.app-body {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  align-items: flex-start;
}
.badge {
  display: inline-flex;
  padding: 0px 8px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  border-radius: 100px;
  align-items: center;
  padding: 0.3rem 1rem;
}
.badge.error {
  background: #fdf0ef;
  color: #c18983;
}
.card {
  border-radius: 12px;
  box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.15);
  min-height: 48px;
  width: calc(100vw - 48px);
}
.card-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 16px 20px;
  font-size: 14px;
  line-height: 19px;
  display: grid;
  grid-template-columns: auto auto;
}
.card-header-dropdown {
  text-align: end;
}
.card-header .fas {
  padding-right: 1rem;
}
.card-header .fa-chevron-down {
  color: #aeaeae;
  padding-right: 0px;
  cursor: pointer;
}
.card-header .fa-chevron-up {
  color: #aeaeae;
  padding-right: 0px;
  cursor: pointer;
}
.card-content {
  background-color: #ffffff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 16px 20px;
}
.show-more {
  text-align: center;
  color: #999999;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}
.show-more > i {
  color: #aeaeae;
}
.field-value-array:not(:first-of-type) {
  padding-top: 1rem;
}
.field-value-array > a {
  color: #295381;
  text-decoration: none;
}
.field-value-array > a:hover {
  text-decoration: underline;
}
.profile {
  background: #e5f5fc;
}
.profile .fa-address-book {
  color: #5d8ba0;
}
.profile .field {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.5rem;
}
.profile .field:not(:last-child) {
  margin-bottom: 2rem;
}
.profile .field-key {
  color: #6d6d6d;
  width: 110px;
}
.field-value .emoji-fire {
  font-size: 12px;
}
.orders {
  background: #fdf0ef;
}
.orders-content {
  display: grid;
  grid-row-gap: 1rem;
}
.order-item {
  background-color: #f4f4f4;
  border-radius: 36px;
  box-shadow: inset 2px 2px 6px rgba(193, 137, 131, 0.2);
  padding: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1rem;
  min-height: 40px;
}
.order-item.error {
  background-color: #fdf0ef;
}
.orders .fa-list-alt {
  color: #c18983;
}
.opportunities {
  background: #e4f9f4;
}
.opportunities .fa-globe {
  color: #73a095;
}
.opportunities.card > .card-content {
  display: grid;
  grid-row-gap: 1rem;
}
.opportunities-item {
  cursor: pointer;
  line-height: 24px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5rem;
}
.opportunities-detail {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-row-gap: 0.5rem;
}
.opportunities-status {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  grid-column-gap: 1rem;
}
.opportunities-send-btn {
  align-self: end;
  padding: 8px 16px;
  background-color: #295381;
  border-radius: 100px;
  color: #ffffff;
  cursor: pointer;
}
#opportunities-send-btn-container {
  display: flex;
  padding: 15px 0;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
}
.opportunities-send-btn-description {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-grow: 1;
}
.restock-date {
  font-size: 12px;
  line-height: 16px;
  color: #ca6c00;
}
.card.interactions {
  background: #fdf4de;
}
.card.interactions .fa-list-alt {
  color: #bb9e54;
}
.interactions.card > .card-content {
  display: grid;
}
.interactions-item {
  padding: 12px;
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
}
.interactions-detail {
  flex-grow: 1;
}
.interactions-device {
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}
.item-avatar {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
  text-align: center;
  line-height: 40px;
}
.item-avatar path {
  fill: #aeaeae;
}
.item-avatar svg {
  display: inline-block;
  vertical-align: sub;
}
@media only screen and (min-width: 1232px) {
  .app-body {
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
  .interactions {
    grid-column: 2;
    grid-row: 1 / 5;
  }
  .card {
    width: calc(50vw - 3rem);
  }
}

.error,
.desktop-device {
  color: #ce6b4a;
}
.success,
.mobile-device {
  color: #58799c;
}
.others,
.others-device-icon {
  color: #6d6d6d;
}
.desktop-device-icon {
  height: 16px;
  width: 16px;
  background-color: #ce6b4a;
  -webkit-mask: url(../images/Desktop.svg) no-repeat center;
  mask: url(../images/Desktop.svg) no-repeat center;
}
.mobile-device-icon {
  height: 16px;
  width: 16px;
  background-color: #58799c;
  -webkit-mask: url(../images/Mobile.svg) no-repeat center;
  mask: url(../images/Mobile.svg) no-repeat center;
}
.others-device-icon {
  height: 16px;
  width: 16px;
  background-color: #6d6d6d;
  -webkit-mask: url(../images/Item.svg) no-repeat center;
  mask: url(../images/Item.svg) no-repeat center;
}
.loading-three-dots {
  height: 35px;
  background-color: #6d6d6d;
  -webkit-mask: url(../images/LoadingThreeDots.svg) no-repeat center;
  mask: url(../images/LoadingThreeDots.svg) no-repeat center;
}
