.authenticating {
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.7);
  z-index: 1;
  text-align: center;
  position: fixed;
}

.loading {
  color: #aeaeae;
  margin: 12px;
}

.loading:after {
  display: inline-block;
  animation: dotty steps(1, end) 1.5s infinite;
  content: "";
}

.failure {
  padding: 15px 30px;
  border: 1px solid red;
  color: #444a52;
}

#diamond {
  margin-top: 30vh;
  animation: fadeIn 0.7s ease-in;
}

/*Column 1 */
#col1-big-circle {
  opacity: 0;
  animation: down-one 1.5s ease-in infinite;
  animation-delay: 0.4s;
}

#col1-small-circle {
  opacity: 0;
  animation: down-two 1.5s ease-in infinite;
  animation-delay: 0.8s;
}

/* Column 2 */
#col2-small-circle {
  opacity: 0;
  animation: down-three 1.5s ease-in infinite;
}

/* Wave and Diamond */
#wave-fill-front {
  animation: wave-front 9s linear infinite alternate;
}

#wave-fill-back {
  animation: wave-back 9s linear infinite alternate;
}

/* waves */
@keyframes wave-front {
  from {
    transform: translate(-556px, 10%);
  }
  to {
    transform: translate(0%, 10%);
  }
}

@keyframes wave-back {
  from {
    transform: translate(0%, 10%);
  }
  to {
    transform: translate(-556px, 10%);
  }
}

/* Bubbles */
@keyframes down-one {
  0%,
  30% {
    opacity: 0;
  }

  35% {
    opacity: 0.5;
  }

  70% {
    opacity: 1;
  }

  85% {
    opacity: 0.7;
  }

  90%,
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

@keyframes down-two {
  0%,
  30% {
    opacity: 0;
  }

  35% {
    opacity: 0.5;
  }

  70% {
    opacity: 1;
  }

  85% {
    opacity: 0.7;
  }

  90%,
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

@keyframes down-three {
  0% {
    opacity: 0;
  }

  5%,
  85% {
    opacity: 0.3;
  }

  30%,
  70% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  90%,
  100% {
    opacity: 0;
    transform: translateY(50%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
