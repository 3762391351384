.app-header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: flex-end;
  row-gap: 0.2rem;
}
.avatar {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-right: 16px;
}
.title {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}
.title-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.2rem;
}
.user-name-section {
  display: flex;
  flex-wrap: wrap;
}
.user-name {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-right: 0.5rem;
}
.badge-vip {
  background: #ecf3fb;
  border-radius: 100px;
  padding: 0px 8px;
  color: #295381;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.badge-vip > span:not(:first-of-type) {
  padding-left: 0.5em;
}
.conversation-id {
  color: #6d6d6d;
}
.title-action {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  align-items: center;
  flex-wrap: wrap;
}
.fas {
  padding-right: 0.3rem;
}
.last-update {
  padding-right: 1rem;
  color: #999999;
  font-size: 0.9rem;
  line-height: 1rem;
}
.refresh-btn {
  background: #295381;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  text-rendering: optimizelegibility;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 1.25rem;
  font-size: 0.8rem;
  font-weight: 550;
  height: 1.7rem;
  line-height: 1;
  overflow: hidden;
  padding: 0px 1rem;
  text-overflow: ellipsis;
  outline: none;
  text-transform: unset;
  transition: transform 0.15s ease-in-out 0s;
  background-color: rgb(41, 83, 129);
  color: rgb(255, 255, 255);
  padding: 0 0.5rem;
}

.refresh-btn > i {
  font-size: 1rem;
}
